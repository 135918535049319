import axios from "axios";

export const inovePagarme = axios.create({
	// baseURL: "http://localhost:3001/api",
	baseURL: "https://pagarme.inoveclube.com/api",
});

export async function saveRecipientService(data) {
	const response = await inovePagarme.post("/createReceiver", data);
	return response.data.recipientId;
}
