import styled from "styled-components";

export const Container = styled.div`
	border-radius: 8px;
	.loading {
		animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
		@keyframes pulse {
			0%,
			100% {
				opacity: 0.5;
			}
			50% {
				opacity: 0.3;
			}
		}
	}
	.printLoading {
		animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
		@keyframes pulse {
			0%,
			100% {
				opacity: 0.5;
			}
			50% {
				opacity: 0.3;
			}
		}
	}
	header {
		background-color: var(--gray-400);
		color: white;
		border-radius: 8px 8px 0 0;
		padding: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 1rem;
		div {
			display: flex;
			flex-direction: column;
			align-items: end;
			gap: 0.3rem;
		}

		.schedule {
			color: var(--mainTheme);
		}

		span:last-child {
			font-size: 0.8rem;
		}
	}
	.wrapper {
		background-color: var(--gray-100);
		border: 1px solid var(--gray-300);
		border-radius: 0 0 8px 8px;
		padding: 1rem;
		display: flex;
		flex-direction: column;
		gap: 1rem;
		.info-wrapper {
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			.title {
				display: flex;
				justify-content: space-between;
				align-items: center;
				gap: 0.5rem;
				div {
					display: flex;
					align-items: center;
					gap: 0.5rem;
				}
				.printer-icon {
					cursor: pointer;
					&:hover {
						opacity: 0.7;
					}
				}
			}
			span:first-child {
				font-weight: 500;
			}
			span:nth-child(2) {
				font-size: 0.8rem;
			}
			.icons-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				span {
					font-size: 1.5rem;
				}
				.action-btns {
					text-align: end;
					.A:first-child {
						background-color: #dc2626;
					}
					.A:last-child {
						background-color: #16a34a;
					}
					.W:first-child {
						background-color: #dc2626;
					}
					.W:last-child {
						background-color: #16a34a;
					}
					.P:first-child {
						background-color: var(--gray-100);
						color: var(--gray-700);
						border: 1px solid var(--gray-700);
					}
					.P:last-child {
						background-color: #60a5fa;
					}
					.E:first-child {
						background-color: var(--gray-100);
						color: var(--gray-700);
						border: 1px solid var(--gray-700);
					}
					.E:last-child {
						background-color: #16a34a;
					}
					button {
						margin-left: 0.5rem;
						margin-top: 0.5rem;
					}
				}
			}
		}
		button {
			padding: 0.5rem 1rem;
			background-color: #8b5cf6;
			border-radius: 8px;
			border: unset;
			color: white;
			align-self: flex-end;
			&:hover {
				opacity: 0.7;
			}
			&:active {
				opacity: 1;
			}
		}
		.toggle-wrapper {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: 0.5rem;
			cursor: pointer;
			.toggle {
				font-weight: 600;
				display: flex;
				align-items: center;
				gap: 0.5rem;
				text-decoration: underline;
				&:hover {
					opacity: 0.8;
				}
			}
		}
		.purchase-infos {
			border-top: 1px dashed;
			border-bottom: 1px dashed;
			margin: 0.5rem;
			padding: 0.5rem;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			font-size: 0.9rem;
			.additionals {
				padding-left: 0.5rem;
				font-size: 0.8rem;
			}
			div {
				display: flex;
				flex-direction: column;
			}
		}
		.purchase-loading {
			padding: 0.5rem;
			border-top: 1px dashed;
			border-bottom: 1px dashed;
			display: flex;
			flex-direction: column;
			gap: 0.5rem;
			min-width: 10rem;
			div {
				background-color: var(--gray-400);
				height: 1rem;
				animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
				@keyframes pulse {
					0%,
					100% {
						opacity: 0.5;
					}
					50% {
						opacity: 0.3;
					}
				}
			}
		}
	}
	.count {
		font-size: 0 0.8rem;
	}
	.btn {
		width: 30px;
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 !important;
		&.whats {
			background-color: #00a884;
			color: #00a884 !important;
			overflow: hidden;
		}
		&.print {
			background-color: #6b7280;
			color: white !important;
		}
	}
	.whats {
		background-color: white !important;
	}
`;

export const RequestConfirmation = styled.div`
	width: 100%;
	height: 100vh;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #4b556351;
	border-radius: 16px;
	box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
	backdrop-filter: blur(5px);
	.wrapper {
		background-color: var(--gray-50);
		border: 1px solid var(--gray-200);
		padding: 1rem 3rem;
		border-radius: 8px;
		position: relative;
		overflow: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		div {
			display: flex;
			gap: 2rem;
			button {
				&:first-child {
					background-color: #ef4444;
				}
				&:nth-child(2) {
					background-color: #22c55e;
				}
				&:focus {
					outline: 2px solid black;
				}
			}
		}
	}
`;
