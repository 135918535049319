import { useEffect, useRef } from "react";
import { useMenu } from "../../../context/MenuContext";
import { formatMoney } from "../../../Utils/formatMoney";

import { Container, CardProduct } from "./style";

export function Group({
  title,
  products,
  setListPositionGroup,
  businessDatas,
  searchResults,
  searchTerm,
}) {
  const groupRef = useRef(null);
  const nameBusiness = window.location.pathname.split("/")[2];

  const {
    handleSetItem,
    addAdditionalPriceToProduct,
    handleSetImageModal,
    fetchAdditionalData,
  } = useMenu();

  useEffect(() => {
    setListPositionGroup(groupRef);
  }, [setListPositionGroup]);

  return (
    <Container ref={groupRef} searchTerm={searchTerm}>
      {searchTerm.length !== "" && searchTerm.trim() !== "" ? (
        searchResults.map((item) =>
          products.map((product) =>
            product.name === item ? (
              <div className="containerProducts">
                <CardProduct
                  key={product.idProduct}
                  linkImage={product.linkImage}
                  searchTerm={searchTerm}
                >
                  {product.linkImage !== "" ? (
                    <div
                      className="image"
                      onClick={() => {
                        handleSetImageModal({
                          show: true,
                          src: product.linkImage,
                        });
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                  <div
                    className="text"
                    onClick={() => {
                      handleSetItem({
                        src: product.linkImage,
                        name: product.name,
                        cost: product.price,
                        enable: product.enabled,
                        assembled: product.isAssembledProduct,
                        sizes: product.sizes,
                        businessDatas,
                        show: true,
                        promotionValue: product.promotionValue,
                        promotions: product.promotions,
                      });
                      addAdditionalPriceToProduct(product.price);
                      fetchAdditionalData(product.idProduct, nameBusiness);
                    }}
                  >
                    <p>{product.name}</p>
                    {product.price > 0 ? (
                      <span>{formatMoney(product.price, businessDatas.formatoMoeda)}</span>
                    ) : (
                      <span>Monte seu produto</span>
                    )}
                  </div>
                </CardProduct>
              </div>
            ) : (
              <></>
            ),
          ),
        )
      ) : (
        <>
          <h3>{title}</h3>

          <div className="containerProducts">
            {products.map((product) => (
              <CardProduct
                key={product.idProduct}
                linkImage={product.linkImage}
                searchTerm={searchTerm}
              >
                {product.linkImage !== "" ? (
                  <div
                    className="image"
                    onClick={() => {
                      handleSetImageModal({
                        show: true,
                        src: product.linkImage,
                      });
                    }}
                  ></div>
                ) : (
                  <></>
                )}
                <div
                  className="text"
                  onClick={() => {
                    handleSetItem({
                      code: product.idProduct,
                      src: product.linkImage,
                      name: product.name,
                      cost: product.price,
                      enable: product.enabled,
                      assembled: product.isAssembledProduct,
                      sizes: product.sizes,
                      businessDatas,
                      show: true,
                      promotionValue: product.promotionValue,
                      promotions: product.promotions,
                    });
                    addAdditionalPriceToProduct(product.price);
                    fetchAdditionalData(product.idProduct, nameBusiness);
                  }}
                >
                  <div className="product">
                    <p>{product.name}</p>
                    {product.additionalInformation.length > 0 && (
                      <span className="additional">{product.additionalInformation}</span>
                    )}
                  </div>

                  {product.price > 0 ? (
                    <span>{formatMoney(product.price, businessDatas.formatoMoeda)}</span>
                  ) : (
                    <span>Monte seu produto</span>
                  )}
                </div>
              </CardProduct>
            ))}
          </div>
        </>
      )}
    </Container>
  );
}
