import { AiOutlineArrowLeft } from "react-icons/ai";
import { FaTrashAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { useMenu } from "../../../context/MenuContext";
import { toast } from "react-toastify";
import { ImSad } from "react-icons/im";
import { api } from "../../../services/api";
import { MainLoader } from "../../../components/loaders/mainLoader";
import { SpaceLoader } from "../../../components/loaders/spaceLoader";
import { formatMoney } from "../../../Utils/formatMoney";
import { EditProductQuantity } from "./EditProductQuantity";
import { useEffect, useState, useMemo, useCallback } from "react";

import { Container, Header, BuyContainer } from "./style";
import { ChoseOrderTypeModal } from "./choseOrderTypeModal";

import { SuggestedProductsList } from "./SuggestedProductsList";

export function CartBuyModal({ listBuying, handleSetListBuying, businessDatas }) {
  const nameBusiness = window.location.pathname.split("/")[2];
  const [isLoading, setIsLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState({
    open: false,
    name: "",
    qtt: 1,
    index: null,
  });

  const [choseOrderTypeModal, setChoseOrderTypeModal] = useState(false);
  const [storeIsOpen, setStoreIsOpen] = useState(false);
  const [scheduling, setScheduling] = useState([]);
  const [cdEmpresa, setCdEmpresa] = useState("");

  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [checkingSuggestedProducts, setCheckingSuggestedProducts] = useState(true);

  const {
    handleSetItem,
    isBuyModalOpen,
    handleSetIsBuyModalOpen,
    setAdditionalDatas,
    setIsLoadingAdditionalProduct,
    productsWithGroups,
  } = useMenu();

  const customId = 100;

  const total = useMemo(() => {
    return listBuying.reduce((sumTotal, product) => {
      return sumTotal + (product.promotionValue ?? product.cost) * product.addCounterProduct;
    }, 0);
  }, [listBuying]);

  const allProductsList = useMemo(() => {
    return productsWithGroups
      .map((item) => item.products)
      .reduce((value, next) => value.concat(next), []);
  }, [productsWithGroups]);

  function handleEditProduct(name, qtt, index) {
    setOpenEdit({ open: true, name, qtt, index });
  }

  function handleCloseEditProduct() {
    setOpenEdit({ open: false, name: "", qtt: 1, index: null });
  }

  function handleConfirmEditProduct(index, quantity) {
    const newArray = [...listBuying];
    newArray[index].addCounterProduct = quantity;

    // Get the new promotion value
    const product = newArray[index];
    if (product.promotions.length) {
      const promotion = product.promotions
        .filter((promotion) => promotion.qtdMinima <= quantity)
        .sort((a, b) => b.qtdMinima - a.qtdMinima)[0];

      if (promotion) {
        newArray[index].promotionValue = promotion.vlPreco;
      } else {
        newArray[index].promotionValue = null;
      }
    }

    handleSetListBuying(newArray);
    localStorage.setItem(
      `@Inoveclube:listBuying ${window.location.pathname.split("/")[2]}`,
      JSON.stringify([...newArray]),
    );

    handleCloseEditProduct();
  }

  function handleCloseModal() {
    handleSetItem({ code: 0, show: false });
    handleSetIsBuyModalOpen(false);
  }

  function handleClearItem(product, indexProduct) {
    const productsFiltered = listBuying.filter((item, index) => {
      return !(item.code === product.code && index === indexProduct);
    });

    productsFiltered.length > 0
      ? localStorage.setItem(
          "@Inoveclube:listBuying" + window.location.pathname.split("/")[2],
          JSON.stringify([...productsFiltered]),
        )
      : localStorage.removeItem(
          "@Inoveclube:listBuying" + window.location.pathname.split("/")[2],
        );
    handleSetListBuying([...productsFiltered]);
  }

  const handleGoToShoppingPage = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/getBusiness/${nameBusiness}/${localStorage.getItem("@Inoveclube:token")}`,
      );
      setCdEmpresa(response.data.idBusiness);
      const { data: schedulingData } = await api.get(
        `/checkTmesToSchedule/${response.data.idBusiness}`,
      );
      setScheduling(schedulingData);
      const { isOpenBusiness, minValue } = response.data;
      const schedulingTimesActive = response.data.schedulingTimesActive;

      const minValueFormatted = formatMoney(minValue, businessDatas.formatoMoeda);

      setIsLoading(false);

      if (minValue > 0 && total < minValue) {
        toast.error(`O valor mínimo de compra neste estabelecimento é: ${minValueFormatted}`);
      } else {
        if (isOpenBusiness && !schedulingTimesActive) {
          if (
            localStorage.getItem(
              "@Inoveclube:listBuying" + window.location.pathname.split("/")[2],
            )
          ) {
            if (localStorage.getItem("@Inoveclube:token")) {
              window.location.href = `${process.env.REACT_APP_URL}shopping`;
            } else {
              localStorage.setItem("@Inoveclube:menu", true);
              window.location.href = `${process.env.REACT_APP_URL}login`;
            }
          } else {
            toast.error("Adicione itens ao carrinho para continuar", {
              toastId: customId,
            });
          }
        } else if (isOpenBusiness && schedulingTimesActive) {
          if (schedulingData.schedulingTimes.length <= 0) {
            if (
              localStorage.getItem(
                "@Inoveclube:listBuying" + window.location.pathname.split("/")[2],
              )
            ) {
              if (localStorage.getItem("@Inoveclube:token")) {
                window.location.href = `${process.env.REACT_APP_URL}shopping`;
              } else {
                localStorage.setItem("@Inoveclube:menu", true);
                window.location.href = `${process.env.REACT_APP_URL}login`;
              }
            } else {
              toast.error("Adicione itens ao carrinho para continuar", {
                toastId: customId,
              });
            }
          } else {
            setStoreIsOpen(true);
            setChoseOrderTypeModal(true);
          }
        } else if (!isOpenBusiness && schedulingTimesActive) {
          if (schedulingData.schedulingTimes.length <= 0) {
            toast.info("O estabelecimento encontra-se fechado no momento", {
              toastId: customId,
            });
          } else {
            setChoseOrderTypeModal(true);
          }
        } else {
          toast.info("O estabelecimento encontra-se fechado no momento", {
            toastId: customId,
          });
        }
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error);
    }
  }, [nameBusiness, total, businessDatas.formatoMoeda]);

  useEffect(() => {
    if (!isBuyModalOpen) handleCloseEditProduct();
  }, [isBuyModalOpen]);

  useEffect(() => {
    const idProducts = [];
    listBuying.map((item) => {
      const hasProduct = idProducts.some((value) => value === item.code);

      if (!hasProduct) {
        idProducts.push(item.code);
      }

      return item;
    });

    async function load() {
      setCheckingSuggestedProducts(true);
      try {
        const response = await api.get(`/suggestedProducts/${nameBusiness}`, {
          params: {
            products: idProducts.join(","),
          },
        });
        setSuggestedProducts(response.data.results);
      } catch (error) {
        setSuggestedProducts([]);
      } finally {
        setCheckingSuggestedProducts(false);
      }
    }

    if (idProducts.length > 0) {
      load();
    }
  }, [listBuying, nameBusiness]);

  async function handleAddItemCart(product) {
    setAdditionalDatas([]);

    const obj = {
      code: product.cdProdutoSugerido,
      src: product.linkImagem,
      name: product.produtoSugerido,
      cost:
        product.vlPromocao && product.vlPromocao > 0 ? product.vlPromocao : product.vlPreco,
      assembled: product.produtoMontado,
      sizes: [],
      listAssemblyItensSelected: [],
      addCounterProduct: 1,
      assembledItensFilled: 0,
      selectedOptions: [],
      size: 0,
      sizeDescription: "",
      textAdditional: "",
      promotionValue: product.vlPromocao,
    };

    if (product.produtoMontado) {
      const productFind = allProductsList.find(
        (item) => item.idProduct === product.cdProdutoSugerido,
      );

      handleSetItem({
        code: product.cdProdutoSugerido,
        src: product.linkImagem,
        name: product.produtoSugerido,
        cost: product.vlPreco,
        enable: product.habilitado,
        assembled: product.produtoMontado,
        sizes: productFind.sizes,
        businessDatas,
        show: true,
        promotionValue: product.vlPromocao,
        promotions: product.promotions,
      });
      handleSetIsBuyModalOpen(false);
      setIsLoadingAdditionalProduct(false);
      return;
    }

    const responseAddional = await api.get(
      `/getAdditionalProduct/${nameBusiness}/${product.cdProdutoSugerido}`,
    );

    if (responseAddional.data && responseAddional.data.length > 0) {
      handleSetItem({
        code: product.cdProdutoSugerido,
        src: product.linkImagem,
        name: product.produtoSugerido,
        cost:
          product.vlPromocao && product.vlPromocao > 0 ? product.vlPromocao : product.vlPreco,
        enable: product.habilitado,
        assembled: product.produtoMontado,
        sizes: undefined,
        businessDatas,
        show: true,
        promotionValue: product.vlPromocao,
        promotions: product.promotions,
      });
      setAdditionalDatas(responseAddional.data);
      handleSetIsBuyModalOpen(false);
      setIsLoadingAdditionalProduct(false);
      return;
    }
    const arr = [...listBuying, obj];

    handleSetListBuying(arr);
    localStorage.setItem(
      "@Inoveclube:listBuying" + window.location.pathname.split("/")[2],
      JSON.stringify(arr),
    );
  }

  function handleCloseChosOrderTypeModal() {
    localStorage.removeItem("@Inoveclube:choseOrderType");
    setChoseOrderTypeModal(false);
  }

  useEffect(() => {
    if (localStorage.getItem("@Inoveclube:choseOrderType")) {
      localStorage.removeItem("@Inoveclube:choseOrderType");
      handleGoToShoppingPage();
    }
  }, [handleGoToShoppingPage]);

  return (
    <>
      {choseOrderTypeModal && (
        <ChoseOrderTypeModal
          toClose={handleCloseChosOrderTypeModal}
          storeIsOpen={storeIsOpen}
          scheduling={scheduling}
          cdEmpresa={cdEmpresa}
        />
      )}

      {isBuyModalOpen ? (
        <Container bgcolor={businessDatas.colorButton}>
          <Header>
            <AiOutlineArrowLeft
              onClick={() => {
                handleSetItem({ code: 0, show: false });
                handleSetIsBuyModalOpen(false);
              }}
            />
            <h1>Pedido</h1>
          </Header>

          {listBuying.length > 0 ? (
            <div className="products-container">
              {listBuying.map((product, index) =>
                product.name === "" ? (
                  <></>
                ) : (
                  <BuyContainer
                    key={product.code}
                    item={product.src}
                    editing={index === openEdit.index}
                  >
                    {product.src === "" ? <></> : <div className="image"></div>}
                    <div className="align">
                      <div className="text">
                        <h3>
                          {product.name} {product.sizeDescription}
                        </h3>
                        <div className="additional-container">
                          {product.listAssemblyItensSelected.length > 0 ? (
                            product.listAssemblyItensSelected.map((element) =>
                              element.filled ? (
                                <span className="additional">
                                  {product.assembledItensFilled === 1
                                    ? "1"
                                    : "1/" + product.assembledItensFilled}{" "}
                                  {element.name}
                                </span>
                              ) : (
                                <></>
                              ),
                            )
                          ) : (
                            <></>
                          )}
                          {product.selectedOptions.map((element) => (
                            <span className="additional">
                              {element.qtd}x {element.obsDescription}
                            </span>
                          ))}
                          {product.textAdditional !== "" ? (
                            <div className="additional">obs: {product.textAdditional}</div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <span className="calc">
                          <span style={{ textDecoration: "line-through", marginRight: "5px" }}>
                            {product.promotionValue &&
                              formatMoney(product.cost, businessDatas.formatoMoeda)}
                          </span>
                          {formatMoney(
                            product.promotionValue ?? product.cost,
                            businessDatas.formatoMoeda,
                          )}{" "}
                          x {product.addCounterProduct}
                        </span>

                        <span className="value">
                          ={" "}
                          {formatMoney(
                            (product.promotionValue ?? product.cost) *
                              product.addCounterProduct,
                            businessDatas.formatoMoeda,
                          )}
                        </span>
                      </div>
                      <div className="buttonsEditClear">
                        <FaTrashAlt onClick={() => handleClearItem(product, index)} />
                        <BiEditAlt
                          onClick={() =>
                            handleEditProduct(product.name, product.addCounterProduct, index)
                          }
                        />
                      </div>
                    </div>
                  </BuyContainer>
                ),
              )}
            </div>
          ) : (
            <div className="cartEmpty">
              <h1>Nenhum produto adicionado ao carrinho</h1>
              <ImSad />
            </div>
          )}

          <EditProductQuantity
            openEdit={openEdit}
            handleCloseEditProduct={handleCloseEditProduct}
            confirmEditProduct={handleConfirmEditProduct}
          />

          {isLoading ? <MainLoader title={"Organizando pedido"} /> : <></>}

          {checkingSuggestedProducts ? (
            <SpaceLoader />
          ) : (
            !isLoading &&
            listBuying.length > 0 &&
            suggestedProducts.length > 0 && (
              <SuggestedProductsList
                products={suggestedProducts}
                handleAddItemCart={handleAddItemCart}
                businessDatas={businessDatas}
              />
            )
          )}

          <div>
            <div className="button-container">
              <button onClick={handleCloseModal} className="close-all">
                Adicionar mais itens
              </button>

              <div className="price-end">
                <div className="total">
                  <span>Total</span>
                  <p>{formatMoney(total, businessDatas.formatoMoeda)}</p>
                </div>

                <button onClick={handleGoToShoppingPage}>Continuar</button>
              </div>
            </div>
          </div>
        </Container>
      ) : (
        <></>
      )}
    </>
  );
}
